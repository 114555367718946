<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 我的悦豆
 * @Date: 2020-12-25 21:07:49
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-25 22:38:16
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/myYuedou.vue
-->
<template>
  <div class="user_content">
    <div class="waller_total">
      <div>悦豆说明
        <van-icon name="question-o" />
      </div>
      <div>{{ hisTotal }}</div>
      <div>当前可以用悦豆，今日获得3个</div>
      <div class="record">去使用</div>
    </div>
    <div class="waller_list">
      <div class="invit_to">
        <div>
          <span>{{ hisTotal }}</span>
          <span>历史总额</span>
        </div>
        <div>
          <span>{{ expire }}</span>
          <span>消费总额</span>
        </div>
        <div>
          <span>{{ consume }}</span>
          <span>过期币额</span>
        </div>
      </div>
      <div class="invitlist">
        <van-tabs v-model="active" @click="tapinv">
          <van-tab title="全部" />
          <van-tab title="收入" />
          <van-tab title="支出" />
          <van-tab title="过期" />
        </van-tabs>
        <ul>
          <li v-for="(item,index) in list" :key="index">
            <div class="listcen">
              <div>{{ item.content }}</div>
              <div>{{ item.addTime }}</div>
            </div>
            <div style="color:#E33F44;"> +{{ item.number }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Tab, Tabs, Toast } from 'vant'

Vue.use(Icon).use(Toast).use(Tab).use(Tabs)
import {
  integralList
} from '@/services/userApi'

export default {
  data() {
    return {
      hisTotal: '',
      consume: '',
      expire: '',
      list: [],
      active: 0,
      type: 0,
      page: 1
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      integralList(this.type, this.page).then(res => {
        if (Number(res.code) === 200) {
          this.hisTotal = res.hisTotal
          this.consume = res.consume
          this.expire = res.expire
          this.list = res.list
        }
      })
    },
    tapinv(v, t) {
      this.type = v
      this.getinfo()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .waller_total {
    padding: 30px 0;
    background: #F7263C;
    height: 150px;

    .record {
      position: absolute;
      top: 50px;
      right: 0;
      color: #F7263C;
      font-size: 14px;
      background: #FFFFFF;
      padding: 4px 5px;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }

    & > div {
      color: #FFFFFF;
      padding: 0 10px;
    }

    & > div:nth-child(1) {
      text-align: left;
    }

    & > div:nth-child(2) {
      font-size: 30px;
      font-weight: 500;
    }

    & > div:nth-child(3) {
      padding: 10px 0 0;
    }
  }

  .waller_list {
    width: 93%;
    position: absolute;
    top: 120px;
    margin: 13px;
    padding: 4px;

    .invit_to {
      display: flex;
      background: #FFFFFF;
      border-radius: 7px;

      & > div {
        flex: 12;

        & > span {
          display: block;
          margin: 10px 0;
        }

        & > span:nth-child(1) {
          color: #E33F44;
          font-size: 17px;
        }
      }
    }
  }

  .invitlist {
    border-radius: 7px;
    margin-top: 10px;
    background: #FFFFFF;
    padding: 3px;

    & > ul {
      margin: 13px;
      height: calc(100vh - 300px);
      overflow: scroll;

      & > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #8888;
        padding: 10px;

        .listcen {
          width: 80%;
          text-align: left;

          & > div {
            padding: 10px 0 0;
          }

          & > div:nth-child(2) {
            color: #999999;
            font-size: 12px;
          }
        }
      }
    }
  }

  .van-tabs {
    border-bottom: 1px solid #8888;
  }
}
</style>
